import { h, render, Component } from 'preact';

import Upcoming from './Upcoming'
import Past from './Past'


render(<Upcoming />, document.querySelector('#upcoming'));

render(<Past />, document.querySelector('#past'));

console.log(`%c                                                                          
  Fitter happier                                                          
  More productive                                                         
  Comfortable                                                             
  Not drinking too much                                                   
  Regular exercise at the gym (3 days a week)                             
  Getting on better with your associate employee contemporaries           
  At ease                                                                 
  Eating well (no more microwave dinners and saturated fats)              
  A patient, better driver                                                
  A safer car (baby smiling in back seat)                                 
  Sleeping well (no bad dreams)                                           
  No paranoia                                                             
  Careful to all animals (never washing spiders down the plughole)        
  Keep in contact with old friends (enjoy a drink now and then)           
  Will frequently check credit at (moral) bank (hole in the wall)         
  Favours for favours                                                     
  Fond but not in love                                                    
  Charity standing orders                                                 
  On Sundays ring road supermarket                                        
  (No killing moths or putting boiling water on the ants)                 
  Car wash (also on Sundays)                                              
  No longer afraid of the dark or midday shadows                          
  Nothing so ridiculously teenage and desperate                           
  Nothing so childish                                                     
  At a better pace                                                        
  Slower and more calculated                                              
  No chance of escape                                                     
  Now self-employed                                                       
  Concerned (but powerless)                                               
  An empowered and informed member of society (pragmatism not idealism)   
  Will not cry in public                                                  
  Less chance of illness                                                  
  Tyres that grip in the wet (shot of baby strapped in back seat)         
  A good memory                                                           
  Still cries at a good film                                              
  Still kisses with saliva                                                
  No longer empty and frantic                                             
  Like a cat                                                              
  Tied to a stick                                                         
  That's driven into                                                      
  Frozen winter shit (the ability to laugh at weakness)                   
  Calm                                                                    
  Fitter, healthier and more productive                                   
  A pig                                                                   
  In a cage                                                               
  On antibiotics                                                          
                                                                          
`, 'background: #000; color: #D45B3B')