const shows = [

    {
    date: "19.10.2024",
    place: "Mazury",
    title: "Beyond Code Conference - Ambient DJ Set"
  },
    {
    date: "20.04.2024",
    place: "Tykocin Castle",
    title: "AutoCafe"
  },
    {
    date: "10.09.2023",
    place: "Tykocin Castle",
    title: "BroFest"
  },
    {
    date: "20.08.2023",
    place: "Galeria Arsenał, Białystok",
    title: "Duby Smalone - Ambient Live Act"
  },
  
    {
    date: "30.06.2023",
    place: "Galeria Arsenał, Białystok",
    title: "Duby Smalone - Ambient DJ Set"
  },
  

    {
    date: "27.05.2023",
    place: "FAMA, Białystok",
    title: "Up To Date Festival b2b Projekt New Hope",
    url: "https://www.facebook.com/events/1031353094511663"
  },
  {
    date: "29.04.2023",
    place: "Drugi Dom, Gdynia",
    title: "Projekt New Hope Showcase"
  },
     
      {
    date: "21.04.2023",
    place: "FAMA, Białystok",
    title: "ELEKTRO TEMPO VOL.3"
  },  
      {
    date: "29.02.2023",
    place: "Radio Kapitał",
    title: "Audycja Projekt New Hope"
  },
  {
    date: "29.01.2023",
place: "Zmiana Klimatu, Białystok",
title: "korsi.space b2b MIFI77 dla WOŚP"
  },
  
  
      {
    date: "25.12.2022",
    place: "❄️🎅",
    title: "Last Christmas na Białówny"
  },
    {
    date: "25.11.2022",
    place: "FOMO, Białystok",
    title: "FOMO_SPECTRAL_4.2: THE VERY POLISH CUT-OUTS | New Hope Crew",
    url: "https://www.facebook.com/events/509368254124896"
  },

  {
    date: "12.11.2022",
    place: "FOMO, Białystok",
    title: "HALOPERIDOL ULTRA - 22 LATA DTEKKA ZA DEKAMI",
    url: "https://www.facebook.com/events/472922871330711"
  },


  {
    date: "30.09.2022",
    place: "FOMO, Białystok",
    title: "FOMO_SPECTRAL: NEW HOPE SHOWCASE X ŁOWCY",
    url: "https://www.facebook.com/events/396143666009820l"
  },
  {
    date: "03.09.2022",
    place: "FOMO, Białystok",
    title: "Up To Date Festival",
    url: "https://uptodate.pl"
  },
  {
    date: "26.08.2022",
    place: "Copenhagen, DK",
    title: "Billetto Summer Party",
  },{
    date: "11.08.2022",
    place: "Rany Julek, Białystok",
    title: "Rany Julek Sushi & Bar chillout",
    url: "https://www.facebook.com/ranyjuleksushibar",
  },
  {
    date: "06.08.2022",
    place: "FOMO, Białystok",
    title: "FOMO: Electrum Ambient Park After Party",
    url: "https://www.facebook.com/events/720349979031363",
  },
  {
    date: "22.07.2022",
    place: "WIKING, Wasilków",
    title: " Walhalla - Electronics Vikings",
    url: "https://www.facebook.com/events/620133846387729",
  },
  {
    date: "18.06.2022",
    place: "FOMO, Białystok",
    title: "Chillout",
  },
  {
    date: "15.06.2022",
    place: "Alicante",
    title: "Billetto hackathon after party beach mix        ",
  },
  {
    date: "21.05.2022",
    place: "NERDS ON LAKES, MIX PORT",
    title: "Nerds On Lakes closing night party w / Decu"
  }, 
  {url: "https://www.facebook.com/events/321715216771751",
date: "06.05.2022",
place: "FOMO, Białystok",
title: "FOMO_SPECTRAL: NEW HOPE SHOWCASE"}, 
{
    date: "17.04.2022",
    place: "FOMO, Białystok",
    title: "FOMO_XTRA: WIELKANOC"
},
{
    url:"https://www.facebook.com/events/685477422900170",
    title:"FOMO_XTRA: TĘCZOWY BIAŁYSTOK",
    date: "08.04.2022",
    place: "FOMO, Białystok"
},
{url: "https://fb.me/e/2ale86lP",
date: "19.03.2022",
place: "FOMO, Białystok",
title: "FOMO_XTRA: UP TO DATE FESTIVAL PRESENTS"
}, 
{
    date: "06.03.2022",
    title: "24 GODZINY DLA UKRAINY", 
    place: "FOMO, Białystok"
},
{
date: "05.03.2022",
place: "FOMO, Białystok",
title: "_SPECTRAL_4.1_MIFI77 B2B NAKED RELAXING, ŁUKASZ POLAKOWSKI | MACIEK KORSAN, DANIEL GŁOWACKI"
},
{
    date: "04.02.2022",
    place: "FOMO, Białystok",
    title: "FOMO_OPEN: MIFI77, KORSAN, ANIA ŁASKARZEWSKA"
},
{
    date: "05.11.2021",
    place: "FOMO, Białystok",
title:"FOMO: new hope - house"
}

];

const Past = () => (
  <ol>
    {shows.map((show, i) => (
      <li className="p-4 mb-4 bg-gray-50/60 rounded md:rounded-lg" key={i}>
        {show.url ? (
          <a
            href={show.url}
            target="_blank"
            rel="noreferrer nofollow"
            class="block"
          >
            <div class="flex justify-between font-stratos-light font-light mb-2 text-sm">
              <span>{show.date}</span>
              <span>{show.place}</span>
            </div>
            <p class="leading-tight">{show.title}</p>
          </a>
        ) : (
          <>
            <div class="flex justify-between font-stratos-light font-light mb-2 text-sm">
              <span>{show.date}</span>
              <span>{show.place}</span>
            </div>
            <p class="leading-tight">{show.title}</p>
          </>
        )}
      </li>
    ))}
   
  </ol>
);

export default Past;
